<template>
    <div style="font-size: 14px">
        <span>{{text}}</span>
        <el-select :clearable=true @change="selectListChange" v-model="selectListvalue" placeholder="请选择行业分类">
            <el-option
                    v-for="item in selectList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
            </el-option>
        </el-select>
    </div>
</template>

<script>
    export default {
        name: "IndustrySelection",
        props: {
            selectList: {
                type: Array,
                default: () => {}
            },
            text: {
                type: String,
                default: ''
            }
        },
        data() {
            return {
                selectListvalue: ''
            }
        },
        methods: {
            selectListChange() {
                this.$emit('selectListChange', this.selectListvalue)
            },
            resetSelectListvalue() {
                this.selectListvalue = ''
            }
        }
    }
</script>

<style scoped>

</style>