<template>
  <el-scrollbar class="newKeFu-wrapper" :native="false">
    <div class="steps-wrapper">
      <div
        class="step-item"
        :class="{ current: [1, 2, 3, 4].includes(stepActive) }"
      >
        <div class="step-line"></div>
        <div class="step-text">1</div>
        <div class="step-title">创建名称</div>
      </div>
      <div
        class="step-item"
        :class="{ current: [2, 3, 4].includes(stepActive) }"
      >
        <div class="step-line"></div>
        <div class="step-text">2</div>
        <div class="step-title">设置商品</div>
      </div>
      <div class="step-item" :class="{ current: [3, 4].includes(stepActive) }">
        <div class="step-line"></div>
        <div class="step-text">3</div>
        <div class="step-title">设置客服数据</div>
      </div>
      <div class="step-item" :class="{ current: [4].includes(stepActive) }">
        <div class="step-line"></div>
        <div class="step-text">4</div>
        <div class="step-title">创建成功</div>
      </div>
    </div>
    <div class="create-content">
      <createStepOne
        ref="createStepOne"
        @stepActiveAdd="stepActiveAdd"
        :editData="editData"
        v-show="stepActive === 1"
      />
      <createStepTwo
        ref="createStepTwo"
        @stepActiveAdd="stepActiveAdd"
        @editchangSelect="editchangSelect"
        @createChangSelect="createChangSelect"
        :editData="editData"
        :createBackData="createBackData"
        v-show="stepActive === 2"
      />
      <createStepThree
        ref="createStepThree"
        @stepActiveAdd="stepActiveAdd"
        @createRobotData="createRobotData"
        :editData="editData"
        :stepTwoGoods="stepTwoGoods"
        :editChangStepThree="editChangStepThree"
        :createChangStepThree="createChangStepThree"
        v-show="stepActive === 3"
      />
      <div class="dialog-footer">
        <el-button v-if="stepActive === 1" @click="toBack">取消</el-button>
        <el-button @click="lastStep" v-if="stepActive !== 1 && stepActive !== 4"
          >上一步</el-button
        >
        <el-button
          style="margin-left: 30px"
          type="primary"
          v-if="stepActive !== 4"
          @click="nextStep"
          >下一步</el-button
        >
      </div>
      <div v-if="stepActive === 4" class="creating-success">
        <div class="createStepSucceed-icon">
          <i class="iconfont">&#xe730;</i>
        </div>
        <span>{{ editId ? "保存成功！" : "创建成功！" }}</span>
        <el-button @click="toList" type="primary">返回列表</el-button>
      </div>
    </div>
  </el-scrollbar>
</template>

<script>
import createStepOne from "./createStepOne.vue";
import createStepTwo from "./createStepTwo.vue";
import createStepThree from "./createStepThree.vue";
import { getKefuData, SaveKefuData } from "@/utils/apis";
export default {
  name: "CreatNewStoreCustomer",
  data() {
    return {
      //步骤
      stepActive: 1,
      // 第一步的数据
      stepOneData: null,
      // 第二步的数据
      stepTwoData: null,
      stepTwoGoods: null, // 第二步数据添加name第三步要用
      // 第三步的数据
      stepThreeData: null,
      editId: this.$route.query.id || "",
      editData: null,
      oldRobotName: [],
      editChangStepThree: null,
      createChangStepThree: null,
      createBackData: [],
    };
  },
  components: {
    createStepOne,
    createStepTwo,
    createStepThree,
  },
  mounted() {
    if (this.editId) {
      this.getEditData();
    }
  },
  methods: {
    // 获取每步的数据
    stepActiveAdd(data) {
      if (this.stepActive === 1) {
        this.stepOneData = data;
        this.stepActive++;
        // let kefu_id = this.stepOneData.kefu_id;
        // let kefu_id = this.$route.query.id;
        // this.$refs.createStepTwo.getAdminGoodListGood(kefu_id);
        this.$refs.createStepTwo.getAdminGoodListGood();
      } else if (this.stepActive === 2) {
        this.stepTwoGoods = data;
        let stepTwoData = JSON.parse(JSON.stringify(data));
        stepTwoData.forEach((item) => {
          delete item.goods_name;
        });
        this.stepTwoData = stepTwoData;
        this.stepActive++;
      } else if (this.stepActive === 3) {
        data.forEach((item, index) => {
          item.sort = index
        })
        console.log(data)
        this.stepThreeData = data;
        let good_data = JSON.stringify(this.stepTwoData);
        let robot_data = JSON.stringify(data);
        let category_id = this.stepOneData.category_id;
        let formData = new FormData();
        formData.append("name", this.stepOneData.name);
        formData.append("category_id", category_id);
        formData.append("repeat_ask_num", this.stepOneData.repeat_ask_num);
        formData.append("wait_time", this.stepOneData.wait_time);
        formData.append("visit_interval", this.stepOneData.visit_interval);
        formData.append("response_time", this.stepOneData.response_time);
        formData.append("score_prop", this.stepOneData.score_prop);
        formData.append("scene", this.stepOneData.scene);
        formData.append("banned_word", this.stepOneData.banned_word);
        formData.append("good_data", good_data);
        formData.append("robot_data", robot_data);

        if (this.editId) {
          formData.append("kefu_id", this.editId);
        }
        SaveKefuData(formData)
          .then((res) => {
            if (res.code === 400) {
              this.$message.warning(res.msg);
            } else {
              this.$message.success(res.msg);
              this.stepActive++;
            }
          })
          .catch((err) => {
            console.log("err", err);
          });
      }
    },
    // 编辑状态时
    getEditData() {
      let params = {
        kefu_id: this.editId,
      };
      getKefuData(params)
        .then((res) => {
          this.editData = res.data;
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    //编辑状态下更改商品信息删除已经设置的客服数据
    editchangSelect(lastGoodsId) {
      if(lastGoodsId) {
        this.editData.robot_data.forEach((item,index) => {
          if(item.good_id==lastGoodsId){
            this.editData.robot_data.splice(index,1)
          }
        });
        this.$refs.createStepThree.getEditData();
      } else {
        this.$refs.createStepThree.getEditData();
      }
    },
    // 创建状态保存机器人数据
    createRobotData(val) {
      if (!this.editData) {
        this.createBackData = val;
      }
    },
    //创建状态下更改商品信息删除已经设置的客服数据
    createChangSelect(lastGoodsId) {
      this.createBackData.forEach((item,index) => {
          if(item.good_id==lastGoodsId){
          this.createBackData.splice(index,1)
        }
      });



       this.$refs.createStepThree.getEditData();
    },

    toBack() {
      this.$router.go(-1);
    },
    toList() {
      this.$router.push("/admin/newstorecustomer");
    },
    lastStep() {
      this.stepActive--;
    },
    nextStep() {
      if (this.stepActive === 1) {
        this.$refs.createStepOne.confirmForm();
      } else if (this.stepActive === 2) {
        this.$refs.createStepTwo.confirmForm();
      } else if (this.stepActive === 3) {
        this.$refs.createStepThree.confirmForm();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.newKeFu-wrapper {
  height: 100%;
  padding-bottom: 50px;
  box-sizing: border-box;
  ::v-deep .el-scrollbar__wrap {
    overflow-x: hidden;
    .el-scrollbar__view {
      padding: 20px;
    }
  }
  .create-content {
    height: 500px;
  }
  .creating-success {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 120px;
    span {
      color: #343434;
      font-size: 24px;
      font-weight: bold;
      margin: 50px 0 50px 10px;
    }
  }
  .dialog-footer {
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    text-align: center;
  }
  .createStepSucceed-icon {
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    i {
      font-size: 52px;
      color: #20d28c;
    }
  }
}
:deep .el-button--primary {
  background-color: #1122d8;
  border: #1122d8;
}
</style>
